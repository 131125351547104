import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { apiUrls } from "../config";
import { TagTypes } from "./tags";

export const api = createApi({
  reducerPath: "janusApi",
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrls.janus,
    credentials: "include",
    timeout: 5000,
    prepareHeaders: async (headers) => {
      const cookie = Cookies.get("csrftoken");
      if (cookie) {
        headers.set("X-CSRFToken", cookie);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: Object.values(TagTypes),
});
