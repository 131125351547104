import LanguagePicker from "../internationalisation/LanguagePicker";

export default function Footer() {
  return (
    <footer>
      <LanguagePicker />© 2008 - 2022 Oxford Nanopore Technologies. All rights
      reserved. Registered Office: Oxford Science Park, Oxford OX4 4DQ, UK |
      Registered No. 05386273 | VAT No 336 9423 82 &nbsp;{" "}
      <a
        href="https://nanoporetech.com/privacy-policy"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>{" "}
      |{" "}
      <a
        href="https://nanoporetech.com/commercial_information"
        target="_blank"
        rel="noreferrer"
      >
        Terms &amp; Conditions
      </a>
      . Oxford Nanopore Technologies, the Wheel icon, GridION, Metrichor,
      MinION, MinKNOW, PromethION and VolTRAX are registered trademarks of
      Oxford Nanopore Technologies Limited in various countries. MinION and
      PromethION are for Research Use Only. This site is protected by reCAPTCHA;
      the Google{" "}
      <a
        href="https://policies.google.com/privacy"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>{" "}
      and{" "}
      <a
        href="https://policies.google.com/terms"
        target="_blank"
        rel="noreferrer"
      >
        Terms of Service
      </a>{" "}
      apply.
    </footer>
  );
}
