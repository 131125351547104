import { FormProvider } from "react-hook-form";

import { useFormSubmit } from "../../../common/form-utils";
import ActionButtonBar from "../../action-buttons/ActionButtonBar";
import InputDevice from "../../input-device/InputDevice";
import { LayoutContent } from "../../layout/Layout";
import LoadingContext from "../../loading-context/LoadingContext";

export default function CatalogueEntrypoint() {
  const { form, control, inProgress, updateRegistrationResult, onSubmit } =
    useFormSubmit();

  return (
    <FormProvider {...form}>
      <LoadingContext isLoading={inProgress}>
        <form onSubmit={onSubmit}>
          <LayoutContent>
            <h2>Register your MinION Starter Pack</h2>
            <p>
              To complete registration and unlock your device, please start by
              entering the serial number found on your device.
            </p>
            <InputDevice className="width-20" control={control} />
          </LayoutContent>
          <ActionButtonBar
            submitStatus={updateRegistrationResult.status}
            submitResult={updateRegistrationResult.data}
            resetSubmit={updateRegistrationResult.reset}
          />
        </form>
      </LoadingContext>
    </FormProvider>
  );
}
