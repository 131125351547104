import {
  NanoCheckbox,
  NanoCheckboxGroup,
} from "@nanoporetech-digital/components-react";
import { Control, Controller } from "react-hook-form";
import useErrorRef from "../../common/input-utils/useErrorRef";
import { PublicRegistration } from "../../types/registration";

export interface InputYesNoProps {
  control: Control<PublicRegistration, any>;
  name: keyof PublicRegistration;
  label: string;
  className?: string;
}

export default function InputYesNo({
  control,
  name,
  label,
  className,
}: InputYesNoProps) {
  const errorRef = useErrorRef<HTMLNanoCheckboxGroupElement>(name);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, onBlur, ref } }) => (
        <NanoCheckboxGroup className={className} legend={label} ref={errorRef}>
          <NanoCheckbox
            name={name}
            label="Yes"
            type="radio"
            checked={value === true}
            onNanoChange={() => onChange(true)}
          />
          <NanoCheckbox
            name={name}
            label="No"
            type="radio"
            checked={value === false}
            onNanoChange={() => onChange(false)}
          />
        </NanoCheckboxGroup>
      )}
    />
  );
}
