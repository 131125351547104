import { createContext, ReactElement } from "react";
import LoadingOverlay from "../loading-overlay/LoadingOverlay";

interface LoadingContextProps {
  children: ReactElement | ReactElement[];
  isLoading: boolean;
}

export const LoadingReactContext = createContext(false);

export default function LoadingContextWrapper({
  children,
  isLoading,
}: LoadingContextProps) {
  return (
    <>
      <LoadingOverlay show={isLoading} />
      <LoadingReactContext.Provider value={isLoading}>
        {children}
      </LoadingReactContext.Provider>
    </>
  );
}
