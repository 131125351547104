import { NanoOption, NanoSelect } from "@nanoporetech-digital/components-react";
import { Control, Controller, useFormContext } from "react-hook-form";
import useErrorRef from "../../common/input-utils/useErrorRef";
import { PublicRegistration } from "../../types/registration";

export interface InputSelectOption {
  label: string;
  value: string;
}

export interface InputSelectProps {
  name: keyof PublicRegistration;
  label: string;
  placeholder: string;
  options: InputSelectOption[];
  control: Control<PublicRegistration, any>;
  className?: string;
  disabled?: boolean;
  changesInvalidate?: (keyof PublicRegistration)[];
}

export default function InputSelect({
  name,
  control,
  label,
  placeholder,
  options,
  className,
  disabled = false,
  changesInvalidate,
}: InputSelectProps) {
  const errorRef = useErrorRef<HTMLNanoSelectElement>(name);
  const form = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, onBlur } }) => (
        <NanoSelect
          label={label}
          placeholder={placeholder}
          floatLabel
          className={className}
          value={value?.toString() || ""}
          onNanoChange={(event) => {
            (changesInvalidate || []).forEach((fieldName) =>
              form.setValue(fieldName, "")
            );
            onChange(event);
          }}
          ref={errorRef}
          disabled={disabled}
        >
          {options.map(({ value, label }) => (
            <NanoOption key={value} value={value} label={label} />
          ))}
        </NanoSelect>
      )}
    />
  );
}
