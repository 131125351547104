import { PublicRegistration } from "../../../types/registration";
import { api } from "../index";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createNewRegistration: builder.mutation<PublicRegistration, string>({
      query: (entrypoint_url) => ({
        url: "/registrations/",
        method: "POST",
        body: { entrypoint_url },
      }),
    }),
  }),
});

export const { useCreateNewRegistrationMutation } = extendedApi;
