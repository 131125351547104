import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { isRejected } from "@reduxjs/toolkit";
import { showError } from "../../services/errors/errorSlice";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejected(action)) {
      if (action.isAborted) {
        console.error("Request timed out", action);
        api.dispatch(showError("Could not reach Oxford Nanopore"));
      }
      if (action?.payload?.status >= 500) {
        console.error("Server gave 5xx error", action);
        api.dispatch(showError("Something went wrong"));
      }
    }

    return next(action);
  };
