import {
  NanoIconButton,
  NanoInput,
} from "@nanoporetech-digital/components-react";
import { useContext, useEffect, useRef, useState } from "react";
import { Control, useFormContext } from "react-hook-form";
import useErrorRef from "../../common/input-utils/useErrorRef";
import RegistrationIdContext from "../../context/RegistrationIdContext";
import { useGetRegistrationQuery } from "../../services/janus/registrations/getRegistration";
import { PublicRegistration } from "../../types/registration";

export interface InputTextProps {
  label: string;
  placeholder: string;
  name: keyof PublicRegistration;
  control: Control<PublicRegistration, any>;
  type?: "textarea" | "text";
  rows?: number;
  className?: string;
}

export default function InputPassword({
  label,
  placeholder,
  name,
  control,
  type = "text",
  rows = 2,
  className,
}: InputTextProps) {
  const id = useContext(RegistrationIdContext);
  if (id === null) {
    throw new Error("No registrations selected");
  }
  const { data: registration } = useGetRegistrationQuery(id);
  const errorRef = useErrorRef<HTMLNanoInputElement>(name);
  const [maskPassword, setMaskPassword] = useState<boolean>(true);
  const [confirmValue, setConfirmValue] = useState("");
  const confirmRef = useRef<HTMLNanoInputElement>(null);
  const form = useFormContext();

  const [isStatic, setIsStatic] = useState(false);

  const password = form.watch("password");

  useEffect(() => {
    setIsStatic(registration !== undefined && registration.has_password);
  }, [setIsStatic, registration]);

  useEffect(() => {
    setConfirmValue("");
  }, [registration]);

  useEffect(() => {
    if (password && password !== "" && password !== confirmValue) {
      confirmRef.current?.showError("Password fields must match");
    } else {
      confirmRef.current?.showError("");
    }
  }, [confirmRef, password, confirmValue]);

  useEffect(() => {
    form.register("password");
  }, [form]);

  return (
    <>
      <NanoInput
        type={maskPassword ? "password" : "text"}
        label={label}
        placeholder={placeholder}
        floatLabel={label !== ""}
        name={name}
        rows={rows}
        onMouseDown={() => setIsStatic(false)}
        onNanoFocus={() => setIsStatic(false)}
        onNanoChange={(event) => {
          if (!isStatic) {
            form.setValue("password", event.target.value, {
              shouldDirty: true,
            });
          }
        }}
        value={isStatic ? "********" : password || ""}
        className={className || ""}
        ref={errorRef}
      >
        <NanoIconButton
          label="Show password"
          slot="end"
          iconName={maskPassword ? "regular/eye" : "regular/eye-slash"}
          onClick={() => setMaskPassword(!maskPassword)}
        />
        <span slot="helper-end">
          Your password must be at least 8 characters, contain a mixture of
          lowercase and uppercase letters with at least 1 number and 1 special
          character
        </span>
      </NanoInput>
      <NanoInput
        value={isStatic ? "********" : confirmValue || ""}
        onMouseDown={() => setIsStatic(false)}
        onNanoFocus={() => setIsStatic(false)}
        onNanoChange={(event) => {
          if (!isStatic) {
            setConfirmValue(event.target.value || "");
          }
        }}
        type={maskPassword ? "password" : "text"}
        label="Confirm password *"
        floatLabel
        ref={confirmRef}
        className={className || ""}
      >
        <NanoIconButton
          label="Show password"
          slot="end"
          iconName={maskPassword ? "regular/eye" : "regular/eye-slash"}
          onClick={() => setMaskPassword(!maskPassword)}
        />
        <span slot="helper-end"></span>
      </NanoInput>
    </>
  );
}
