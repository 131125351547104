import {
  InputChangeEventDetail,
  NanoInputCustomEvent,
} from "@nanoporetech-digital/components";
import {
  NanoDatalist,
  NanoIcon,
  NanoInput,
  NanoOption,
  NanoSpinner,
} from "@nanoporetech-digital/components-react";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Control, useFormContext } from "react-hook-form";
import useErrorRef from "../../common/input-utils/useErrorRef";
import {
  OrganisationSearchPayload,
  useGetOrganisationSuggestionsQuery,
} from "../../services/janus/organisations/getOrganisationSuggestions";
import { PublicRegistration } from "../../types/registration";

interface InputOrganisationNameProps {
  control: Control<PublicRegistration, any>;
}

export default function InputOrganisationName({
  control,
}: InputOrganisationNameProps) {
  const form = useFormContext();

  const errorRef = useErrorRef<HTMLNanoInputElement>("organisation_name");

  const [searchTerm, setSearchTerm] = useState<OrganisationSearchPayload>({
    searchTerm: "",
    countryId: "",
  });
  const [hasFocus, setHasFocus] = useState(false);
  const [isStale, setIsStale] = useState(false);
  const [selection, setSelection] = useState<{
    name: string;
    duns: string;
  } | null>(null);

  const { setValue, watch } = form;

  const countryId = watch("organisation_country");

  const {
    data = [],
    error,
    isFetching,
  } = useGetOrganisationSuggestionsQuery(searchTerm);

  const debouncedCall = useMemo(
    () =>
      debounce(
        (newSearchTerm: string) =>
          setSearchTerm({ searchTerm: newSearchTerm, countryId }),
        300
      ),
    [setSearchTerm, countryId]
  );

  useEffect(() => {
    form.register("organisation_duns");
  }, [form]);

  const org_name_value = form.watch("organisation_name");
  const org_duns_value = form.watch("organisation_duns");

  const handleChange = useCallback(
    (event: NanoInputCustomEvent<InputChangeEventDetail>) => {
      const value = event.target.value || "";
      if (selection && selection.name === value) {
        setValue("organisation_name", selection.name, {
          shouldDirty: true,
        });
      } else {
        if (value && value.length > 2) {
          setIsStale(true);
          debouncedCall(event.target.value || "");
        }
        setValue("organisation_name", event.target.value, {
          shouldDirty: true,
        });
      }
    },
    [debouncedCall, setValue, setIsStale, selection]
  );

  useEffect(() => {
    if (selection && selection.name === org_name_value) {
      setValue("organisation_duns", selection.duns, {
        shouldDirty: true,
      });
    } else {
      setValue("organisation_duns", "", {
        shouldDirty: true,
      });
    }
  }, [setValue, selection, org_name_value]);

  useEffect(() => {
    setIsStale(false);
  }, [error, data, setIsStale]);

  const showResults =
    !error && data.length > 0 && org_name_value && org_name_value.length > 2;

  return (
    <>
      <NanoInput
        className="width-40"
        onNanoChange={handleChange}
        onNanoFocus={() => setHasFocus(true)}
        onNanoBlur={() => setHasFocus(false)}
        label="Organisation name*"
        value={org_name_value}
        ref={errorRef}
        floatLabel
        disabled={countryId === null}
      >
        <NanoDatalist disableFilter>
          {!showResults && !isFetching && (
            <NanoOption value="" disabled>
              <div style={{ color: "#555" }}>
                {org_name_value === null || org_name_value.length <= 2 ? (
                  <>Type at least two characters to get started</>
                ) : (
                  <>
                    <strong>No organisations were found</strong> <br />
                    If you are sure the organisation name is correct, you can
                    still continue with registration.
                  </>
                )}
              </div>
            </NanoOption>
          )}
          {(showResults ? data : []).map((entry) => (
            <NanoOption
              key={entry.duns}
              value={entry.name}
              onNanoSelect={() => {
                setIsStale(false);
                setSelection({
                  name: entry.name,
                  duns: entry.duns,
                });
                form.setValue("organisation_duns", entry.duns, {
                  shouldDirty: true,
                });
                form.setValue("organisation_name", entry.name, {
                  shouldDirty: true,
                });
              }}
            >
              <div>
                <strong>{entry.name}</strong>
                <br />
                {entry.address.street_lines.join(", ")} {entry.address.city}{" "}
                {entry.address.country.name}
              </div>
            </NanoOption>
          ))}
        </NanoDatalist>
        <NanoSpinner
          style={{ opacity: isStale || isFetching ? 1 : 0 }}
          slot="end"
          type="circle"
        />
        <NanoIcon slot="end" name="light/search" />
      </NanoInput>
      {!hasFocus && org_name_value && !org_duns_value && (
        <p
          style={{
            marginTop: "-1rem",
            color: "orange",
            fontWeight: "bold",
            fontSize: "0.8rem",
          }}
        >
          <NanoIcon name="regular/info-circle" /> Unknown organisation -
          registration may be faster if you can select your organisation from
          the list above.
        </p>
      )}
    </>
  );
}
