import { NanoInput } from "@nanoporetech-digital/components-react";
import { Control, Controller } from "react-hook-form";
import useErrorRef from "../../common/input-utils/useErrorRef";
import { PublicRegistration } from "../../types/registration";

export interface InputTextProps {
  label: string;
  placeholder: string;
  name: keyof PublicRegistration;
  control: Control<PublicRegistration, any>;
  type?: "textarea" | "text" | "email";
  rows?: number;
  className?: string;
  autoCapitalize?: boolean;
}

export default function InputText({
  label,
  placeholder,
  name,
  control,
  type = "text",
  rows = 2,
  className,
  autoCapitalize = true,
}: InputTextProps) {
  const errorRef = useErrorRef<HTMLNanoInputElement>(name);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, onBlur } }) => (
        <NanoInput
          label={label}
          placeholder={placeholder}
          floatLabel={label !== ""}
          name={name}
          rows={rows}
          type={type}
          onNanoChange={onChange}
          value={value?.toString() || ""}
          className={className || ""}
          ref={errorRef}
          autoCapitalize={autoCapitalize ? "on" : "off"}
        />
      )}
    />
  );
}
