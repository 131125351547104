import { RegistrationId } from "../../../types/registration";
import { api } from "../index";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRegistrationCheatCode: builder.query<string | null, RegistrationId>({
      query: (id) => `/registrations/${id}/get_verification_code_for_testing/`,
    }),
  }),
});

export const { useGetRegistrationCheatCodeQuery } = extendedApi;
