import { api } from "../index";

export interface OrganisationSearchPayload {
  countryId: string;
  searchTerm: string;
}

interface OrganisationSuggestion {
  name: string;
  duns: string;
  address: {
    country: {
      name: string;
    };
    city: string;
    street_lines: string[];
  };
}

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrganisationSuggestions: builder.query<
      OrganisationSuggestion[],
      OrganisationSearchPayload
    >({
      query: ({ countryId, searchTerm }) => {
        return `/organisations/search/?country_id=${
          countryId || ""
        }&search_term=${searchTerm || ""}`;
      },
      transformResponse: ({ data }) => data,
    }),
  }),
});

export const { useGetOrganisationSuggestionsQuery } = extendedApi;
