import Layout, { LayoutContent } from "../features/layout/Layout";

interface HttpErrorProps {
  code: number;
}

export function Http404Error() {
  return (
    <>
      <h4>Sorry, this page has been moved or deleted.</h4>
    </>
  );
}

export function HttpDefaultError() {
  return (
    <>
      <h4>Sorry, this page has been moved or deleted.</h4>
    </>
  );
}

export default function HttpError({ code }: HttpErrorProps) {
  let inner = <HttpDefaultError />;
  if (code === 404) {
    inner = <Http404Error />;
  }
  return (
    <Layout>
      <LayoutContent>{inner}</LayoutContent>
    </Layout>
  );
}
