import { useContext } from "react";
import RegistrationIdContext from "../../context/RegistrationIdContext";
import { useGetRegistrationQuery } from "../../services/janus/registrations/getRegistration";
import { PublicRegistration, ReasonType } from "../../types/registration";

export default function useErrors(
  fieldName: keyof PublicRegistration | null | undefined
): null | string {
  const id = useContext(RegistrationIdContext);
  if (id === null) {
    throw new Error("No registrations selected");
  }
  const { data: registration } = useGetRegistrationQuery(id);
  if (!registration) {
    return null;
  }
  const determined = registration.actions.determined.transition !== null;

  return registration.actions.determined.combined_validation
    .filter(
      (validation) => fieldName === undefined || validation.field === fieldName
    )
    .filter(
      (validation) =>
        validation.reason_type === ReasonType.ERROR ||
        (validation.reason_type === ReasonType.DISCRIMINATED && determined)
    )
    .map(({ reason }) => reason)
    .filter((reason) => reason !== null)
    .join("; ");
}
