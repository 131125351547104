import "@nanoporetech-digital/components/dist/nano-components/nano-components.css";
import "@nanoporetech-digital/components/dist/themes/nanopore.css";

import { defineCustomElements } from "@nanoporetech-digital/components/loader";
import React from "react";
import ReactDOM from "react-dom/client";
import { RootApp } from "./app";
import build from "./common/build";
import "./index.scss";
import { init } from "./logging";
import { apiUrls } from "./services/config";

init();

console.log(`
  🧬🧬🧬 Oxford Nanopore Customer Registration 🧬🧬🧬
  
  Root: ${build.root}
  Env: ${build.env}
  Version: ${build.ref}
  Commit: ${build.commit}
  
  APIs
  ----
  
  Janus: ${apiUrls.janus}
  MyAccount: ${apiUrls.myAccount}
  
  © Oxford Nanopore Technologies Ltd. 2022

`);

defineCustomElements(undefined, { resourcesUrl: "/" });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RootApp />
  </React.StrictMode>
);
