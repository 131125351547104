import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import RegistrationIdContext from "../context/RegistrationIdContext";
import { showError } from "../services/errors/errorSlice";
import { useApplyTransitionMutation } from "../services/janus/registrations/applyTransition";
import { useGetRegistrationQuery } from "../services/janus/registrations/getRegistration";
import { useSetPasswordMutation } from "../services/janus/registrations/setPassword";
import { useUpdateRegistrationMutation } from "../services/janus/registrations/updateRegistration";
import { PublicRegistration } from "../types/registration";
import { getAutoTransition } from "./utils";

export function useFormSubmit() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const id = useContext(RegistrationIdContext);
  if (id === null) {
    throw new Error("No registrations selected");
  }

  const dispatch = useDispatch();

  const {
    data: registration,
    isFetching,
    refetch,
  } = useGetRegistrationQuery(id);
  if (!registration) {
    throw new Error("Registration not loaded");
  }

  const form = useForm({ defaultValues: registration });

  const { handleSubmit, reset, control } = form;

  const [applyTransition] = useApplyTransitionMutation();
  const [updateRegistration, updateRegistrationResult] =
    useUpdateRegistrationMutation();
  const [setPassword] = useSetPasswordMutation();

  const [isSaving, setIsSaving] = useState(false);

  const inProgress = useMemo(() => {
    return isSaving || isFetching || updateRegistrationResult.isLoading;
  }, [updateRegistrationResult.isLoading, isSaving, isFetching]);

  const submitHandler = useCallback(
    async (values: Partial<PublicRegistration>) => {
      setIsSaving(true);
      if (values.password && values.password !== "") {
        try {
          await setPassword({ id, password: values.password }).unwrap();
        } catch (err) {
          console.error("Failed to set password", id, err);
          dispatch(showError("unexpected"));
          return;
        }
      }
      let updateResult;
      try {
        updateResult = await updateRegistration({
          id,
          data: values,
        }).unwrap();
      } catch (err) {
        console.error("Failed to update registration", id, err);
        dispatch(showError("unexpected"));
        return;
      }
      const transition = getAutoTransition(updateResult);

      if (transition) {
        try {
          await applyTransition({
            id,
            transitionName: transition.name,
          }).unwrap();
        } catch (err) {
          console.error("Failed to transition state", id, err);
          dispatch(showError("unexpected"));
          return;
        }
      }
      const after = await refetch().unwrap();

      reset(after, { keepSubmitCount: true });

      setIsSaving(false);
    },
    [
      dispatch,
      setPassword,
      applyTransition,
      setIsSaving,
      updateRegistration,
      id,
      refetch,
      reset,
    ]
  );

  const onSubmit = useMemo(() => {
    return handleSubmit(submitHandler);
  }, [handleSubmit, submitHandler]);

  return {
    submitHandler,
    inProgress,
    updateRegistrationResult,
    handleSubmit,
    form,
    control,
    registration,
    onSubmit,
  };
}
