import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Registration from "../pages/Registration";
import UnknownError from "../pages/UnknownError";

const router = createBrowserRouter([
  {
    path: "*",
    element: <Registration />,
    errorElement: <UnknownError />,
  },
]);

export default function Router() {
  return <RouterProvider router={router} />;
}
