import { configureStore } from "@reduxjs/toolkit";
import { errorSlice } from "../services/errors/errorSlice";
import { api as janusApi } from "../services/janus";
import { api as loqateApi } from "../services/loqate";
import { rtkQueryErrorLogger } from "./middleware/errorCatching";

export const store = configureStore({
  reducer: {
    [janusApi.reducerPath]: janusApi.reducer,
    [loqateApi.reducerPath]: loqateApi.reducer,
    [errorSlice.name]: errorSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: true,
      immutableCheck: true,
    }).concat(janusApi.middleware, loqateApi.middleware, rtkQueryErrorLogger),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
