import { useCallback, useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { getAutoTransition } from "../../common/utils";
import RegistrationIdContext from "../../context/RegistrationIdContext";
import { useApplyTransitionMutation } from "../../services/janus/registrations/applyTransition";
import { useGetRegistrationQuery } from "../../services/janus/registrations/getRegistration";
import { useUpdateRegistrationMutation } from "../../services/janus/registrations/updateRegistration";
import { PublicRegistration } from "../../types/registration";
import ActionButtonBar from "../action-buttons/ActionButtonBar";
import InputConfirmBox from "../input-confirm-box/InputConfirmBox";
import InputDevice from "../input-device/InputDevice";
import InputOrganisationCountry from "../input-organisation-country/InputOrganisationCountry";
import InputOrganisationName from "../input-organisation-name/InputOrganisationName";
import InputOrganisationRegion from "../input-organisation-region/InputOrganisationRegion";
import InputOrganisationType from "../input-organisation-type/InputOrganisationType";
import InputText from "../input-text/InputText";
import InputYesNo from "../input-yes-no/InputYesNo";
import LoadingOverlay from "../loading-overlay/LoadingOverlay";

export default function RegformIrNoninvitedDeviceDraft() {
  const id = useContext(RegistrationIdContext);
  if (id === null) {
    throw new Error("No registrations selected");
  }
  const [saving, setIsSaving] = useState(false);
  const [applyTransition] = useApplyTransitionMutation();
  const [updateRegistration, updateRegistrationResult] =
    useUpdateRegistrationMutation();
  const {
    data: registration,
    refetch,
    isFetching,
  } = useGetRegistrationQuery(id);
  if (!registration) {
    throw new Error("Registration not loaded");
  }

  const form = useForm({ defaultValues: registration });

  const { handleSubmit, reset, control } = form;

  // Submit the form
  const submitHandler = useCallback(
    async (values: Partial<PublicRegistration>) => {
      setIsSaving(true);

      const updateResult = await updateRegistration({
        id,
        data: values,
      }).unwrap();
      const transition = getAutoTransition(updateResult);

      if (transition) {
        await applyTransition({ id, transitionName: transition.name }).unwrap();
      }
      const after = await refetch().unwrap();

      reset(after);

      setIsSaving(false);
    },

    [applyTransition, refetch, reset, id, updateRegistration]
  );

  return (
    <FormProvider {...form}>
      <LoadingOverlay
        show={saving || updateRegistrationResult.isLoading || isFetching}
      />
      <form onSubmit={handleSubmit(submitHandler)}>
        <h3>Account registration</h3>
        <p>
          To complete the registration and unlock your devices, please confirm
          the following information.
        </p>
        <p style={{ fontSize: ".8rem" }}>Fields marked with * are required.</p>

        <h4>Organisation details</h4>
        <InputOrganisationName control={control} />

        <InputOrganisationType control={control} label="Organisation type*" />
        <InputText
          className="width-40"
          name="organisation_website"
          label="Website*"
          placeholder=""
          control={control}
        />
        <InputOrganisationCountry
          forChannelPartner={registration.channel_partner}
          label="Country*"
          name="organisation_country"
          control={control}
        />
        <InputOrganisationRegion
          countryFieldName="organisation_country"
          label="Region*"
          name="organisation_region"
          control={control}
        />
        <InputText
          className="width-40"
          name="organisation_postal_code"
          label="Zip/Postal code*"
          placeholder=""
          control={control}
        />

        <h4>Register your device</h4>
        <InputDevice className="width-25" control={control} />

        <h4>Confirmation of use</h4>
        <InputYesNo
          className="width-40"
          label="Are you intending to resell, distribute or provide the products to a third party? *"
          name="use_resell"
          control={control}
        />

        <InputYesNo
          className="width-40"
          label="Will the products be used for military or nuclear applications or military or nuclear end-use purposes? *"
          name="use_military_nuclear"
          control={control}
        />

        <p className="width-40">
          Please provide information regarding your intended use of Nanopore
          products and technology *
        </p>
        <InputText
          className="width-40"
          type="textarea"
          rows={4}
          name="use_statement"
          label=""
          placeholder=""
          control={control}
        />

        <p>
          Please review our{" "}
          <a href="https://nanoporetech.com/commercial_information">
            terms and conditions
          </a>
          .
        </p>
        <InputConfirmBox
          className="width-40"
          label="I agree to Oxford Nanopore's terms and conditions"
          name="terms_and_conditions_agreement"
          control={control}
        />

        <ActionButtonBar
          submitStatus={updateRegistrationResult.status}
          submitResult={updateRegistrationResult.data}
          resetSubmit={updateRegistrationResult.reset}
        />
      </form>
    </FormProvider>
  );
}
