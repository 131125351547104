import {
  PublicRegistration,
  RegistrationTransition,
} from "../types/registration";

export function getAutoTransition(
  registration: PublicRegistration
): RegistrationTransition | undefined {
  if (registration.actions.determined.combined_validation.length === 0) {
    return registration.actions.all.find(
      (action) => action.name === registration.actions.determined.transition
    );
  }
}
