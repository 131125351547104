import { FormProvider } from "react-hook-form";

import { useFormSubmit } from "../../../common/form-utils";
import ActionButtonBar from "../../action-buttons/ActionButtonBar";
import InputPassword from "../../input-password/InputPassword";
import InputPersonalTitle from "../../input-personal-title/InputPersonalTitle";
import InputText from "../../input-text/InputText";
import { LayoutContent } from "../../layout/Layout";
import LoadingOverlay from "../../loading-overlay/LoadingOverlay";

export default function CataloguePersonalDetails() {
  const {
    form,
    control,
    handleSubmit,
    submitHandler,
    inProgress,
    updateRegistrationResult,
  } = useFormSubmit();

  return (
    <FormProvider {...form}>
      <LoadingOverlay show={inProgress} />
      <form onSubmit={handleSubmit(submitHandler)}>
        <LayoutContent>
          <h3>Account registration</h3>

          <p className="dodge-aside">
            Please confirm the following information.
          </p>

          <aside>
            <h1 className="when-gt-mid">Privacy policy</h1>
            <p>
              Our{" "}
              <a
                href="https://nanoporetech.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                privacy policy
              </a>{" "}
              sets out how Oxford Nanopore Technologies and its subsidiaries
              handle your personal information.
            </p>
          </aside>

          <p style={{ fontSize: ".8rem" }}>
            Fields marked with * are required.
          </p>
          <h4>Personal details</h4>
          <InputPersonalTitle
            className="width-10"
            name="personal_title"
            control={control}
          />
          <InputText
            className="width-20"
            name="first_name"
            label="First name*"
            placeholder=""
            control={control}
          />
          <InputText
            className="width-20"
            name="last_name"
            label="Last name*"
            placeholder=""
            control={control}
          />
          <InputText
            className="width-30"
            name="contact_email_unverified"
            label="Email*"
            placeholder=""
            type="email"
            control={control}
          />
          <InputPassword
            name="password"
            label="New password*"
            placeholder=""
            className="width-inner-15"
            control={control}
          />
          <h4>Telephone number*</h4>
          <InputText
            className="width-25"
            name="contact_telephone"
            label="Telephone*"
            placeholder=""
            control={control}
          />
        </LayoutContent>
        <ActionButtonBar
          submitStatus={updateRegistrationResult.status}
          submitResult={updateRegistrationResult.data}
          resetSubmit={updateRegistrationResult.reset}
        />
      </form>
    </FormProvider>
  );
}
