import { useContext } from "react";
import RegistrationIdContext from "../../context/RegistrationIdContext";
import { useGetRegistrationQuery } from "../../services/janus/registrations/getRegistration";
import { RegistrationProgressState } from "../../types/registration";
import RegistrationConclusion from "../registration-conclusion/RegistrationConclusion";

export default function RegformIrComplete() {
  const id = useContext(RegistrationIdContext);
  if (id === null) {
    throw new Error("No registrations selected");
  }
  const { data: registration } = useGetRegistrationQuery(id, {
    pollingInterval: 5000,
  });

  return (
    <RegistrationConclusion
      state={registration?.progress_state || RegistrationProgressState.EMPTY}
    />
  );
}
