import { NanoSpinner } from "@nanoporetech-digital/components-react";
import { useEffect, useState } from "react";

interface RegistrationOngoingProps {
  progress: number;
}

export default function RegistrationOngoing({
  progress,
}: RegistrationOngoingProps) {
  const [loadedTime, setLoadedTime] = useState(0);
  const [sinceLoaded, setSinceLoaded] = useState(0);

  useEffect(() => {
    setLoadedTime(new Date().valueOf());
    const interval = setInterval(
      () => setSinceLoaded(new Date().valueOf() - loadedTime),
      100
    );
    return () => clearInterval(interval);
  }, [loadedTime, setLoadedTime]);

  if (sinceLoaded > 60000) {
    return (
      <>
        <h1>This is taking longer than usual</h1>
        <p>Leave it with us and we'll get back to you.</p>
      </>
    );
  }

  return (
    <>
      <h2>Thank you for verifying your email</h2>
      <p>Please wait while we create your account.</p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "3rem",
          padding: "2rem 0",
        }}
      >
        <NanoSpinner style={{ width: "10rem" }} />
      </div>
    </>
  );
}
