import { api } from "../index";

interface RegistrationJoinOption {
  account_address: string;
  account_name: string;
  account_number: string;
  account_channel_partners: string[];
}

interface RegistrationJoinOptions {
  blocked: boolean;
  options: RegistrationJoinOption[];
}

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRegistrationOptions: builder.mutation<RegistrationJoinOptions, string>({
      query: (payload) => ({
        url: `/registrations/${payload}/get_registration_options/`,
        method: "GET",
        timeout: 300000,
      }),
    }),
  }),
});

export const { useGetRegistrationOptionsMutation } = extendedApi;
