import { useContext } from "react";
import { languagePackOptions } from "./Internationalisation";
import LanguagePickerContext from "./LanguagePickerContext";

export default function LanguagePicker() {
  const { set, value } = useContext(LanguagePickerContext);
  return (
    <>
      <ul style={{ listStyle: "none", padding: 0, display: "none" }}>
        {languagePackOptions.map((language) => (
          <li key={language.defaultLocale}>
            {language.flag} {language.defaultLocale === value ? "→ " : ""}
            <button
              onClick={() => set(language.defaultLocale)}
              style={{ cursor: "pointer", color: "white" }}
            >
              {language.label}
            </button>
          </li>
        ))}
      </ul>
    </>
  );
}
