import {
  PublicRegistration,
  RegistrationId,
} from "../../../types/registration";
import { api } from "../index";

export interface UpdateRegistrationPayload {
  id: RegistrationId;
  data: Partial<PublicRegistration>;
}

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateRegistration: builder.mutation<
      PublicRegistration,
      UpdateRegistrationPayload
    >({
      query: (payload) => ({
        url: `/registrations/${payload.id}/`,
        method: "PATCH",
        body: payload.data,
      }),
    }),
  }),
});

export const { useUpdateRegistrationMutation } = extendedApi;
