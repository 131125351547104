import { useContext } from "react";
import RegistrationIdContext from "../../../context/RegistrationIdContext";
import { useGetRegistrationQuery } from "../../../services/janus/registrations/getRegistration";
import { LayoutContent } from "../../layout/Layout";
import MyAccountLoginButton from "../../myaccount-login-button/MyAccountLoginButton";

export default function CatalogueRepeat() {
  const id = useContext(RegistrationIdContext);
  if (id === null) {
    throw new Error("Registration not selected.");
  }
  const { data: registration } = useGetRegistrationQuery(id, {
    pollingInterval: 5000,
  });

  if (!registration) {
    throw new Error("Registration not loaded.");
  }

  return (
    <LayoutContent>
      <h1>Welcome back</h1>
      <p>
        We've seen you here before. Please log in to your account to continue.
      </p>
      <MyAccountLoginButton />
    </LayoutContent>
  );
}
