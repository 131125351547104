import {
  PublicRegistration,
  RegistrationId,
} from "../../../types/registration";
import { api } from "../index";

export interface ApplyTransitionPayload {
  id: RegistrationId;
  transitionName: string;
}

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    applyTransition: builder.mutation<
      PublicRegistration,
      ApplyTransitionPayload
    >({
      query: (payload) => ({
        url: `/registrations/${payload.id}/transitions/${payload.transitionName}/`,
        method: "POST",
      }),
    }),
  }),
});

export const { useApplyTransitionMutation } = extendedApi;
