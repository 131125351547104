import Crumbs, { Crumb } from "./Crumbs";
import styles from "./Layout.module.scss";

export interface LayoutProps {
  children: JSX.Element | (JSX.Element | null)[] | null;
  crumbs?: Crumb[];
}

export default function Layout({ children, crumbs }: LayoutProps) {
  return (
    <div className={styles.layout}>
      <img
        height="48"
        width="271"
        src="/assets/ont-logo.svg"
        className="logo"
        alt="The Oxford Nanopore Logo"
      />
      {crumbs && <Crumbs values={crumbs} />}
      <main>{children}</main>
    </div>
  );
}

export interface LayoutContentProps {
  children: JSX.Element | (JSX.Element | null)[] | null;
}

export function LayoutContent({ children }: LayoutContentProps) {
  return <div style={{ padding: "1.5rem 2rem" }}>{children}</div>;
}
