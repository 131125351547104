import { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { clearError } from "../../services/errors/errorSlice";
import Layout, { LayoutContent } from "../layout/Layout";

interface ErrorDisplayProps {
  children: ReactElement;
}

export default function ErrorDisplay({ children }: ErrorDisplayProps) {
  const error = useSelector((state: RootState) => state.errors.message);
  const dispatch = useDispatch();
  if (error === null) {
    return children;
  }

  return (
    <Layout>
      <LayoutContent>
        <h1>
          <FormattedMessage
            id={`error.${error}.title`}
            defaultMessage="Something went wrong"
          />
        </h1>
        <p>
          <FormattedMessage
            id={`error.${error}.body`}
            defaultMessage="You may be able to re-try and continue your registration. If you are seeing this message repeatedly you may need to try again later."
          />
        </p>
        {error !== "notFound" ? (
          <button
            onClick={() => dispatch(clearError())}
            className="button button--primary"
          >
            <FormattedMessage
              id="button.continue.label"
              defaultMessage="Continue"
            />
          </button>
        ) : null}
      </LayoutContent>
    </Layout>
  );
}
