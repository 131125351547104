import { RegistrationTransition } from "../../types/registration";
import ActionButton from "./ActionButton";

export interface ResolvedActionProps {
  resolved: RegistrationTransition;
  loading: boolean;
}

export default function ResolvedAction({
  resolved,
  loading,
}: ResolvedActionProps) {
  return (
    <ActionButton loading={loading} buttonType="submit" transition={resolved} />
  );
}
