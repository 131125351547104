import { useContext } from "react";
import RegistrationIdContext from "../../context/RegistrationIdContext";
import { useApplyTransitionMutation } from "../../services/janus/registrations/applyTransition";
import { useGetRegistrationQuery } from "../../services/janus/registrations/getRegistration";
import { RegistrationTransition } from "../../types/registration";
import ActionButton from "./ActionButton";

export interface UnresolvedActionProps {
  transition: RegistrationTransition;
  loading: boolean;
  buttonType?: "button" | "submit";
}

export default function UnresolvedAction({
  transition,
  loading,
  buttonType = "button",
}: UnresolvedActionProps) {
  const id = useContext(RegistrationIdContext);
  if (id === null) {
    throw new Error("No registrations selected");
  }
  const { refetch } = useGetRegistrationQuery(id);

  const [applyTransition] = useApplyTransitionMutation();
  return (
    <ActionButton
      loading={loading}
      buttonType={buttonType}
      transition={transition}
      onClick={() =>
        buttonType === "button" &&
        applyTransition({ id, transitionName: transition.name })
          .unwrap()
          .then(refetch)
      }
    />
  );
}
