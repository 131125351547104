import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import RegistrationIdContext from "../../context/RegistrationIdContext";
import HttpError from "../../pages/HttpError";
import { showError } from "../../services/errors/errorSlice";
import { useCreateNewRegistrationMutation } from "../../services/janus/registrations/createNewRegistration";
import Layout from "../layout/Layout";
import RegformSkeleton from "../regform-skeleton/RegformSkeleton";
import RegistrationFlow from "./RegistrationFlow";
import ResumeRegistration from "./ResumeRegistration";

function activeRegistrationKey(url: string) {
  return `janus:activeRegistrationId@${url}`;
}

const storageEngine = window.sessionStorage;

export interface RegistrationManagerProps {
  entrypointUrl: string;
}

export default function RegistrationManager({
  entrypointUrl,
}: RegistrationManagerProps) {
  const [shouldUseExisting, setShouldUseExisting] = useState<boolean | null>(
    null
  );

  const dispatch = useDispatch();

  const existingId = useMemo(
    () => storageEngine.getItem(activeRegistrationKey(entrypointUrl)),
    // We want to re-run when shouldUseExisting changes so:
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entrypointUrl, shouldUseExisting]
  );

  const [createRegistration, newRegistration] =
    useCreateNewRegistrationMutation();

  useEffect(() => {
    if (!existingId) {
      createRegistration(entrypointUrl)
        .unwrap()
        .then(({ id }) =>
          storageEngine.setItem(activeRegistrationKey(entrypointUrl), id)
        )
        .catch((err) => dispatch(showError("notFound")));
    }
  }, [dispatch, existingId, createRegistration, entrypointUrl]);

  if (existingId && shouldUseExisting) {
    return (
      <RegistrationIdContext.Provider value={existingId}>
        <RegistrationFlow />
      </RegistrationIdContext.Provider>
    );
  }

  if (existingId) {
    return (
      <ResumeRegistration
        useExisting={() => setShouldUseExisting(true)}
        startAgain={() => {
          window.sessionStorage.setItem(
            activeRegistrationKey(entrypointUrl),
            ""
          );
          setShouldUseExisting(false);
        }}
      />
    );
  }

  if (newRegistration.isLoading || newRegistration.isUninitialized) {
    return (
      <Layout>
        <RegformSkeleton />
      </Layout>
    );
  }

  if (newRegistration.isError) {
    const { error } = newRegistration;
    if ("status" in error && typeof error.status === "number") {
      return <HttpError code={error.status} />;
    }
    throw new Error(JSON.stringify(error));
  }

  if (newRegistration.isSuccess) {
    return (
      <RegistrationIdContext.Provider value={newRegistration.data.id}>
        <RegistrationFlow />
      </RegistrationIdContext.Provider>
    );
  }

  throw new Error("Unexpected error");
}
