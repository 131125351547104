import { useMemo } from "react";
import { Control, useFormContext } from "react-hook-form";
import { useGetRegionsForCountryQuery } from "../../services/janus/regions/getRegionsForCountry";
import { PublicRegistration } from "../../types/registration";
import InputSelect from "../input-select/InputSelect";

interface InputOrganisationRegionProps {
  name: keyof PublicRegistration;
  countryFieldName: keyof PublicRegistration;
  label: string;
  control: Control<PublicRegistration, any>;
}

function InputOrganisationRegionWithCountry({
  countryFieldName,
  name,
  label,
  control,
}: InputOrganisationRegionProps) {
  const { watch } = useFormContext();
  const watchedCountry: string = watch(countryFieldName);
  const {
    data: regionOptions = [],
    isLoading,
    isFetching,
  } = useGetRegionsForCountryQuery(watchedCountry);

  const options = useMemo(
    () =>
      regionOptions.map(({ synonyms: { default: label }, id }) => ({
        label,
        value: id.toString(),
      })),
    [regionOptions]
  );

  return (
    <InputSelect
      control={control}
      name={name}
      label={label}
      placeholder="-- Select --"
      className="width-25"
      options={options}
      disabled={options.length === 0 || isFetching || isLoading}
    />
  );
}

export default function InputOrganisationRegion({
  countryFieldName,
  name,
  label,
  control,
}: InputOrganisationRegionProps) {
  const { watch } = useFormContext();
  const watchedCountry: string = watch(countryFieldName);

  if (!watchedCountry) {
    return (
      <InputSelect
        control={control}
        name={name}
        label={label}
        placeholder="-- Select --"
        className="width-25"
        options={[]}
        disabled
      />
    );
  }
  return (
    <InputOrganisationRegionWithCountry
      {...{ countryFieldName, name, label, control }}
    />
  );
}
