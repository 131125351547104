import { IntlProvider } from "react-intl";

import { useMemo, useState } from "react";
import en from "../../languages/en.json";
import zh from "../../languages/zh.json";
import LanguagePickerContext from "./LanguagePickerContext";

const languagePreferenceKey = `janus:languagePreference`;

const browserLanguage = navigator.language || "en-GB";
const userLanguage = window.localStorage.getItem(languagePreferenceKey);

interface LanguagePack {
  flag: string;
  label: string;
  weight: number;
  matches: string;
  defaultLocale: string;
  messages: Record<string, string>;
}

export const languagePackOptions: LanguagePack[] = [en, zh].sort(
  (a: LanguagePack, b: LanguagePack) => (a.weight > b.weight ? -1 : 1)
);

function getLanguagePack(language: string): LanguagePack {
  const fallbackPack = languagePackOptions[0];
  const selectedPack =
    languagePackOptions.find((pack) => language.match(pack.matches)) ||
    fallbackPack;
  const messages = { ...selectedPack.messages, ...fallbackPack.messages };
  return { ...selectedPack, ...{ messages } };
}

interface InternationalisationProps {
  children: JSX.Element;
}

export default function Internationalisation({
  children,
}: InternationalisationProps) {
  const [language, setLanguage] = useState(userLanguage || browserLanguage);
  const languagePack = useMemo(() => getLanguagePack(language), [language]);
  const languagePickerContextValue = useMemo(
    () => ({
      set: (language: string) => {
        setLanguage(language);
        window.localStorage.setItem(languagePreferenceKey, language);
      },
      value: language,
    }),
    [language, setLanguage]
  );

  return (
    <LanguagePickerContext.Provider value={languagePickerContextValue}>
      <IntlProvider locale={language} messages={languagePack.messages}>
        {children}
      </IntlProvider>
    </LanguagePickerContext.Provider>
  );
}
