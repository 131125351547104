const janus = process.env.REACT_APP_JANUS_API_ROOT;
const myAccount = process.env.REACT_APP_MYACCOUNT_ROOT;
const loqate = process.env.REACT_APP_LOQATE_API_ROOT;

if (!janus) {
  throw new Error("Must set Janus API root");
}
if (!myAccount) {
  throw new Error("Must set MyAccount root");
}
if (!loqate) {
  throw new Error("Must set Loqate API root");
}

export const apiUrls = {
  janus: janus as string,
  myAccount: myAccount as string,
  loqate: loqate as string,
};
