import { RefObject, useEffect, useRef } from "react";
import { PublicRegistration } from "../../types/registration";
import useErrors from "./useErrors";
import useShouldShowErrors from "./useShouldShowErrors";

type SupportedElement =
  | HTMLNanoSelectElement
  | HTMLNanoInputElement
  | HTMLNanoCheckboxGroupElement;

export default function useErrorRef<T extends SupportedElement>(
  name: keyof PublicRegistration
): RefObject<T> {
  const ref = useRef<T>(null);
  const errors = useErrors(name);
  const shouldShowErrors = useShouldShowErrors(name);

  useEffect(() => {
    if (shouldShowErrors) {
      ref.current?.showError(errors || "");
    } else {
      ref.current?.showError("");
    }
  }, [ref, errors, shouldShowErrors]);

  return ref;
}
