import { NanoSkeleton } from "@nanoporetech-digital/components-react";
import { LayoutContent } from "../layout/Layout";

export default function RegformSkeleton() {
  return (
    <LayoutContent>
      <NanoSkeleton style={{ width: "10rem", margin: "1rem" }} />
      <NanoSkeleton style={{ width: "20rem", margin: "1rem" }} />
      <NanoSkeleton style={{ width: "20rem", margin: "1rem" }} />
      <NanoSkeleton
        style={{ width: "30rem", height: "10rem", margin: "1rem" }}
      />
    </LayoutContent>
  );
}
