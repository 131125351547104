import {
  NanoCheckbox,
  NanoCheckboxGroup,
} from "@nanoporetech-digital/components-react";
import { Control, Controller } from "react-hook-form";
import useErrorRef from "../../common/input-utils/useErrorRef";
import { useGetOrganisationTypeOptionsQuery } from "../../services/janus/options/getOrganisationTypeOptions";
import { PublicRegistration } from "../../types/registration";

export interface InputTitleProps {
  control: Control<PublicRegistration, any>;
  label: string;
}

export default function InputOrganisationType({
  control,
  label,
}: InputTitleProps) {
  const { data: orgTypeOptions = [] } = useGetOrganisationTypeOptionsQuery();
  const options = orgTypeOptions.map(({ value, id }) => ({
    label: value,
    value: id.toString(),
  }));
  if (options.length === 0) {
    options.push({ label: "", value: "" });
  }

  const name = "organisation_type";
  const errorRef = useErrorRef<HTMLNanoCheckboxGroupElement>(name);

  return (
    <Controller
      control={control}
      name="organisation_type"
      render={({ field: { onChange, value, onBlur, ref } }) => (
        <NanoCheckboxGroup className="width-30" legend={label} ref={errorRef}>
          {options.map((option) => (
            <NanoCheckbox
              name={name}
              checked={value?.toString() === option.value}
              key={option.value}
              type="segment"
              value={option.value}
              onNanoChange={onChange}
            >
              {option.label}
            </NanoCheckbox>
          ))}
        </NanoCheckboxGroup>
      )}
    />
  );
}
