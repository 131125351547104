import { useMemo } from "react";
import { Control } from "react-hook-form";
import { useGetTitleOptionsQuery } from "../../services/janus/options/getTitleOptions";
import { PublicRegistration } from "../../types/registration";
import InputSelect from "../input-select/InputSelect";

export interface InputTitleProps {
  name: keyof PublicRegistration;
  control: Control<PublicRegistration, any>;
  className: string;
}

export default function InputPersonalTitle({
  name,
  control,
  className,
}: InputTitleProps) {
  const { data: titleOptions = [] } = useGetTitleOptionsQuery();

  const options = useMemo(
    () =>
      titleOptions.map(({ value, id }) => ({
        label: value,
        value: id.toString(),
      })),
    [titleOptions]
  );

  return (
    <InputSelect
      className={className}
      control={control}
      name={name}
      label="Title *"
      placeholder="-- Select --"
      options={options}
    />
  );
}
