import { PublicRegistration } from "../../../types/registration";
import { api } from "../index";
import { TagTypes } from "../tags";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendVerificationEmail: builder.mutation<PublicRegistration, string>({
      query: (payload) => ({
        url: `/registrations/${payload}/send_verification_email/`,
        method: "POST",
      }),
      invalidatesTags: (result, error, query) => [
        { type: TagTypes.Registration, id: query },
      ],
    }),
  }),
});

export const { useSendVerificationEmailMutation } = extendedApi;
