import { RegistrationProgressState } from "../../../types/registration";
import MyAccountLoginButton from "../../myaccount-login-button/MyAccountLoginButton";

interface RegistrationConclusionProps {
  state: RegistrationProgressState;
}

export default function RegistrationConclusion({
  state,
}: RegistrationConclusionProps) {
  switch (state) {
    case RegistrationProgressState.EMPTY:
    case RegistrationProgressState.POPULATING:
    case RegistrationProgressState.SYNCING_SALESFORCE:
    case RegistrationProgressState.SYNCED_SALESFORCE:
    case RegistrationProgressState.SYNCING_OCTOPUS:
    case RegistrationProgressState.SYNCED_OCTOPUS:
    case RegistrationProgressState.REQUESTED_MYACCOUNT:
    case RegistrationProgressState.CREATED_MYACCOUNT:
    case RegistrationProgressState.APPROVED_MYACCOUNT:
    case RegistrationProgressState.COMPLETED_SALESFORCE:
    case RegistrationProgressState.FINISHED:
      return (
        <>
          <h1>Registration complete</h1>
          <p>
            Thank you for completing registration. You will be contacted when
            your account is ready.
          </p>
        </>
      );
    case RegistrationProgressState.FAILED:
      return (
        <>
          <h1>Something went wrong</h1>
          <p>We encountered an error while trying to register your account.</p>
        </>
      );
    case RegistrationProgressState.FINISHED_PREEXISTING_ACR:
    case RegistrationProgressState.FINISHED_PREEXISTING_DIRECT_ACR:
      return (
        <>
          <h1>Welcome back</h1>
          <p>
            We've seen you here before. Please log in to your account to
            continue.
          </p>
          <MyAccountLoginButton />
        </>
      );
    default:
      throw new Error(`Unrecognised progress state: ${state}`);
  }
}
