import { Paginated } from "../../../types/api";
import { Country } from "../../../types/options";
import { api } from "../index";

interface GetCountryOptionsPayload {
  channelPartner: number | null;
}

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCountryOptions: builder.query<Country[], GetCountryOptionsPayload>({
      query: ({ channelPartner }) =>
        `/countries/${
          channelPartner !== null
            ? "?channel_partner=" + channelPartner.toString()
            : ""
        }`,
      transformResponse: (response: Paginated<Country>) => response.results,
    }),
  }),
});

export const { useGetCountryOptionsQuery } = extendedApi;
