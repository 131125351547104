import { PublicRegistration } from "../../../types/registration";
import { api } from "../index";

interface SetPasswordPayload {
  password: string;
  id: string;
}

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    setPassword: builder.mutation<PublicRegistration, SetPasswordPayload>({
      query: (payload) => ({
        url: `/registrations/${payload.id}/set_password/`,
        method: "PATCH",
        body: { password: payload.password },
      }),
    }),
  }),
});

export const { useSetPasswordMutation } = extendedApi;
