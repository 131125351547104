import { RegistrationProgressState } from "../../types/registration";
import MyAccountLoginButton from "../myaccount-login-button/MyAccountLoginButton";
import RegistrationOngoing from "./RegistrationOngoing";

interface RegistrationConclusionProps {
  state: RegistrationProgressState;
}

export default function RegistrationConclusion({
  state,
}: RegistrationConclusionProps) {
  const nSteps = 10;
  switch (state) {
    case RegistrationProgressState.EMPTY:
      return <RegistrationOngoing progress={0 / nSteps} />;
    case RegistrationProgressState.POPULATING:
      return <RegistrationOngoing progress={1 / nSteps} />;
    case RegistrationProgressState.SYNCING_SALESFORCE:
      return <RegistrationOngoing progress={2 / nSteps} />;
    case RegistrationProgressState.SYNCED_SALESFORCE:
      return <RegistrationOngoing progress={3 / nSteps} />;
    case RegistrationProgressState.SYNCING_OCTOPUS:
      return <RegistrationOngoing progress={4 / nSteps} />;
    case RegistrationProgressState.SYNCED_OCTOPUS:
      return <RegistrationOngoing progress={5 / nSteps} />;
    case RegistrationProgressState.REQUESTED_MYACCOUNT:
      return <RegistrationOngoing progress={6 / nSteps} />;
    case RegistrationProgressState.CREATED_MYACCOUNT:
      return <RegistrationOngoing progress={7 / nSteps} />;
    case RegistrationProgressState.APPROVED_MYACCOUNT:
      return <RegistrationOngoing progress={8 / nSteps} />;
    case RegistrationProgressState.COMPLETED_SALESFORCE:
    case RegistrationProgressState.FINISHED:
      return (
        <>
          <h1>Registration complete</h1>
          <p>
            Thank you for completing registration. Your account is now ready.
          </p>
          <MyAccountLoginButton />
        </>
      );
    case RegistrationProgressState.FAILED:
      return (
        <>
          <h1>Something went wrong</h1>
          <p>We encountered an error while trying to register your account.</p>
        </>
      );
    default:
      throw new Error(`Unrecognised progress state: ${state}`);
  }
}
