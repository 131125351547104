import { NanoSkeleton } from "@nanoporetech-digital/components-react";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { useContext, useEffect, useRef, useState } from "react";
import RegistrationIdContext from "../../context/RegistrationIdContext";
import { useGetRegistrationQuery } from "../../services/janus/registrations/getRegistration";
import {
  PublicRegistration,
  RegistrationTransition,
  TransitionType,
} from "../../types/registration";
import styles from "./ActionButtonBar.module.scss";
import BestFitOption from "./BestFitOption";
import ResolvedAction from "./ResolvedAction";
import UnresolvedAction from "./UnresolvedAction";

function showTransitionIfNotResolved(transition: RegistrationTransition) {
  return (
    transition.transition_type === TransitionType.TRANSITION_CANCEL ||
    transition.transition_type === TransitionType.TRANSITION_BACK
  );
}

export interface ActionButtonsProps {
  submitStatus: QueryStatus;
  submitResult: PublicRegistration | undefined;
  resetSubmit: () => void;
}

export default function ActionButtonBar({
  submitStatus,
  submitResult,
  resetSubmit,
}: ActionButtonsProps) {
  const id = useContext(RegistrationIdContext);
  const ref = useRef<HTMLDivElement>(null);
  const [isStuck, setIsStuck] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const { current } = ref;
      const observer = new IntersectionObserver(
        () => {
          const clientRect = current.getClientRects()[0];
          setIsStuck(clientRect.bottom > window.innerHeight);
        },
        {
          root: document,
          rootMargin: "0px",
          threshold: 1.0,
        }
      );
      observer.observe(current);
      return () => observer.unobserve(current);
    }
  }, [ref]);

  if (id === null) {
    throw new Error("No registrations selected");
  }
  const { data: registration } = useGetRegistrationQuery(id);

  if (registration === undefined) {
    return <NanoSkeleton style={{ width: "10rem" }} />;
  }

  const resolved = registration.actions.all.find(
    ({ name }) => name === registration.actions.determined.transition
  );
  const shownAnyway = registration.actions.all.filter(
    showTransitionIfNotResolved
  );

  const beforeDivider = shownAnyway.filter(
    ({ button }) => button?.alignment === "start"
  );
  const afterDivider = shownAnyway.filter(
    ({ button }) => button?.alignment === "end"
  );

  return (
    <div
      ref={ref}
      className={`${styles.actionbuttonbar} ${
        isStuck ? styles.isstuck : styles.notstuck
      }`}
    >
      {beforeDivider.map((transition) => (
        <UnresolvedAction
          loading={submitStatus === QueryStatus.pending}
          key={transition.name}
          transition={transition}
        />
      ))}
      <div style={{ flexGrow: 1 }} />
      {afterDivider.map((transition) => (
        <UnresolvedAction
          loading={submitStatus === QueryStatus.pending}
          key={transition.name}
          transition={transition}
        />
      ))}
      {resolved ? (
        <ResolvedAction
          loading={submitStatus === QueryStatus.pending}
          resolved={resolved}
        />
      ) : (
        <BestFitOption />
      )}
    </div>
  );
}
