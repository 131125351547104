import { NanoInput } from "@nanoporetech-digital/components-react";
import { useEffect, useRef, useState } from "react";
import { Control, Controller, useFormContext } from "react-hook-form";
import useErrorRef from "../../common/input-utils/useErrorRef";
import { PublicRegistration } from "../../types/registration";

interface InputDeviceProps {
  control: Control<PublicRegistration, any>;
  className: string;
}

const name = "device_serial_number_unverified";

export default function InputDevice({ control, className }: InputDeviceProps) {
  const form = useFormContext();
  const errorRef = useErrorRef<HTMLNanoInputElement>(name);
  const [confirm, setConfirm] = useState("");
  const confirmRef = useRef<HTMLNanoInputElement>(null);

  const value = form.watch(name);

  useEffect(() => {
    confirmRef.current?.showError(
      value === "" || value === null || confirm === value
        ? ""
        : "Please check values are the same"
    );
  }, [value, confirmRef, confirm]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <>
          <NanoInput
            label="Device serial number"
            className={className}
            value={value}
            onNanoChange={onChange}
            floatLabel
            onPaste={(e) => e.preventDefault()}
            ref={errorRef}
          />
          <NanoInput
            label="Confirm device serial number"
            className={className}
            value={confirm}
            onNanoChange={(event) => setConfirm(event.target.value || "")}
            floatLabel
            onPaste={(e) => e.preventDefault()}
            ref={confirmRef}
          />
        </>
      )}
    />
  );
}
