import { NanoSkeleton } from "@nanoporetech-digital/components-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import build from "../../../common/build";
import useErrors from "../../../common/input-utils/useErrors";
import { getAutoTransition } from "../../../common/utils";
import RegistrationIdContext from "../../../context/RegistrationIdContext";
import { showError } from "../../../services/errors/errorSlice";
import { useApplyTransitionMutation } from "../../../services/janus/registrations/applyTransition";
import { useGetRegistrationQuery } from "../../../services/janus/registrations/getRegistration";
import { useGetRegistrationCheatCodeQuery } from "../../../services/janus/registrations/getRegistrationCheatCode";
import { useSendVerificationEmailMutation } from "../../../services/janus/registrations/sendVerificationEmail";
import { useUpdateRegistrationMutation } from "../../../services/janus/registrations/updateRegistration";
import ActionButtonBar from "../../action-buttons/ActionButtonBar";
import CompoundBox from "../../compound-box/CompoundBox";
import { LayoutContent } from "../../layout/Layout";
import LoadingOverlay from "../../loading-overlay/LoadingOverlay";
import CatalogueRepeat from "../catalogue-repeat/CatalogueRepeat";

function Cheat({ id }: { id: string }) {
  const { refetch } = useGetRegistrationCheatCodeQuery(id);
  const [done, setDone] = useState("");
  return (
    <button
      id="devtools-get-code"
      style={{ position: "fixed", left: "5px", bottom: "5px", padding: "5px" }}
      onClick={() =>
        refetch()
          .unwrap()
          .then((d) => {
            setTimeout(() => setDone(""), 5000);
            setDone(d || "Code could not be loaded");
          })
      }
    >
      {done !== "" ? done : "🔎 Devtools - get code"}
    </button>
  );
}

export default function CatalogueEmailVerification() {
  const id = useContext(RegistrationIdContext);
  if (id === null) {
    throw new Error("Registration not selected.");
  }
  const {
    data: registration,
    isFetching,
    refetch,
  } = useGetRegistrationQuery(id);

  const [value, setValue] = useState("");

  const [updateRegistration, updateRegistrationResult] =
    useUpdateRegistrationMutation();
  const [sendVerificationEmail] = useSendVerificationEmailMutation();
  const [applyTransition] = useApplyTransitionMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (registration) {
      const transition = getAutoTransition(registration);
      if (transition) {
        applyTransition({ id, transitionName: transition.name })
          .unwrap()
          .then(() => refetch())
          .catch((err) => {
            console.error("Failed to transition after email verification", err);
            dispatch(showError("unexpected"));
          });
      }
    }
  }, [dispatch, applyTransition, id, refetch, registration]);

  useEffect(() => {
    if (value.length === 6) {
      updateRegistration({
        id,
        data: { email_verification_submitted_code: value },
      })
        .unwrap()
        .then(() => refetch().unwrap())
        .then(() => setHasSubmitted(true))
        .catch((err) => {
          console.error("Failed to transition after email verification", err);
          dispatch(showError("unexpected"));
        });
    }
  }, [dispatch, refetch, value, id, updateRegistration]);

  const [resendLockedout, setResendLockedout] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleResendEmail = useCallback(() => {
    setHasSubmitted(false);
    setResendLockedout(true);
    sendVerificationEmail(id);
    const timeout = setTimeout(() => setResendLockedout(false), 10000);
    return () => clearTimeout(timeout);
  }, [id, sendVerificationEmail, setHasSubmitted, setResendLockedout]);

  const errors = useErrors(undefined);

  if (
    registration &&
    registration.actions.determined &&
    registration.actions.determined.combined_validation.find(
      ({ name }) => name === "check_email_not_already_handled"
    )
  ) {
    return <CatalogueRepeat />;
  }

  return (
    <>
      <LoadingOverlay show={updateRegistrationResult.isLoading || isFetching} />
      <LayoutContent>
        <h1>Please verify your email</h1>
        <p>
          An email with a verification code has been sent to{" "}
          <strong>
            {registration ? (
              registration.contact_email_unverified
            ) : (
              <NanoSkeleton style={{ width: "100px", height: "1rem" }} />
            )}
          </strong>
        </p>
        <p>Please enter the code below to continue.</p>
        <p style={{ color: "red", fontSize: "1rem" }}>
          {hasSubmitted && !isFetching ? errors : ""}
        </p>
        <div
          style={{ display: "flex", gap: "0.5rem", justifyContent: "center" }}
        >
          <CompoundBox
            structure={[1, 1, 1, 1, 1, 1]}
            value={value}
            setValue={setValue}
          />
        </div>
        <p style={{ textAlign: "right", marginTop: "2rem" }}>
          Not received the email?{" "}
          <button
            disabled={resendLockedout}
            onClick={handleResendEmail}
            style={{ marginLeft: "2rem" }}
            className="button button--light"
          >
            Send new code
          </button>
        </p>
      </LayoutContent>
      <ActionButtonBar
        submitStatus={updateRegistrationResult.status}
        submitResult={updateRegistrationResult.data}
        resetSubmit={updateRegistrationResult.reset}
      />
      {build.devtools && <Cheat id={id} />}
    </>
  );
}
