import Cookies from "js-cookie";
import { apiUrls } from "./services/config";

interface LogEntry {
  level: "log" | "error" | "info";
  time: string;
  message: any;
  detail?: any;
}

export const logHistory: LogEntry[] = [];

const cookie = Cookies.get("csrftoken");

function phoneHome() {
  try {
    const url = `${apiUrls.janus}clientlogs/ingress/`;
    const errorAsJson = {
      time: new Date().toISOString(),
      url: document.location.toString(),
      client: {
        userAgent: navigator.userAgent,
        cookieEnabled: navigator.cookieEnabled,
      },
      logs: logHistory,
    };
    fetch(url, {
      method: "POST",
      body: JSON.stringify(errorAsJson),
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie || "",
      },
      credentials: "include",
    })
      .then((response) => {})
      .catch(() => {});
  } catch (err) {}
}

export function init() {
  const { log, info, error } = console;

  console.log = (...args) => {
    logHistory.push({
      level: "log",
      time: new Date().toISOString(),
      message: args,
    });
    log(...args);
  };

  console.error = (...args) => {
    logHistory.push({
      level: "error",
      time: new Date().toISOString(),
      message: args,
    });
    phoneHome();
    error(...args);
  };

  console.info = (...args) => {
    logHistory.push({
      level: "info",
      time: new Date().toISOString(),
      message: args,
    });
    info(...args);
  };
}
