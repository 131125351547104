import { NanoIcon } from "@nanoporetech-digital/components-react";
import { RegistrationTransition } from "../../types/registration";

export interface ActionButtonProps {
  transition: RegistrationTransition;
  onClick?: () => void;
  buttonType?: "submit" | "button";
  loading: boolean;
}

export default function ActionButton({
  transition,
  onClick,
  buttonType = "submit",
  loading,
}: ActionButtonProps) {
  const button = transition.button;
  if (!button) {
    return null;
  }
  return (
    <button
      type={buttonType}
      onClick={onClick}
      className={`button-bar__continue button button--${button.variant}`}
      style={{
        alignSelf: button.alignment,
      }}
      disabled={loading}
    >
      {button.label} <NanoIcon name={button.icon} />
    </button>
  );
}
