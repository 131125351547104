import {
  ChangeEventHandler,
  ClipboardEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  MutableRefObject,
  useMemo,
  useRef,
} from "react";

interface CompoundBoxInputProps {
  index: number;
  length: number;
  totalLength: number;
  value: string;
  setValue: (newValue: string) => void;
  refSet: MutableRefObject<Array<HTMLInputElement | null>>;
  startCharIdx: number;
}

function CompoundBoxInput({
  refSet,
  index,
  length,
  value,
  setValue,
  startCharIdx,
  totalLength,
}: CompoundBoxInputProps) {
  const subValue = value.slice(startCharIdx, startCharIdx + length);

  const prev = () => refSet.current[index - 1];

  const next = () => refSet.current[index + 1];

  const last = () => refSet.current.slice(-1)[0];

  const handleFocus: FocusEventHandler<HTMLInputElement> = (event) => {
    const target = event.target as HTMLInputElement;
    if (!target.value) {
      prev()?.focus();
    }
  };

  const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = (event) => {
    const target = event.target as HTMLInputElement;
    if (event.key !== "Backspace" && target.value.length >= length) {
      next()?.focus();
    }
    if (event.key === "Backspace" && target.value.length === 0) {
      prev()?.focus();
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const target = event.target as HTMLInputElement;
    const pre = value.slice(0, startCharIdx);
    const post = value.slice(startCharIdx + length);
    setValue(`${pre}${target.value}${post}`.slice(0, totalLength));
  };

  const handlePaste: ClipboardEventHandler<HTMLInputElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setValue(
      event.clipboardData
        .getData("Text")
        .replaceAll(/[^\d]/g, "")
        .slice(0, totalLength)
    );
    last()?.focus();
  };

  return (
    <input
      style={{
        width: `${2 + length}rem`,
        padding: ".75rem",
        fontSize: "1.5rem",
        textAlign: "center",
      }}
      title="Partial input"
      onKeyUp={handleKeyUp}
      onFocus={handleFocus}
      onChange={handleChange}
      onPaste={handlePaste}
      ref={(el) => {
        refSet.current[index] = el;
      }}
      value={subValue}
    />
  );
}

interface CompoundBoxProps {
  value: string;
  setValue: (newValue: string) => void;
  structure: number[];
}

export default function CompoundBox({
  value,
  setValue,
  structure,
}: CompoundBoxProps) {
  const refSet = useRef<Array<HTMLInputElement | null>>([]);
  const boxes = useMemo(() => {
    let startCharIdx = 0;
    return structure.map((length, index) => {
      const result = { index, length, startCharIdx };
      startCharIdx += length;
      return result;
    });
  }, [structure]);
  const totalLength = boxes
    .map(({ length }) => length)
    .reduce((acc, val) => acc + val, 0);
  return (
    <>
      {boxes.map((item) => (
        <CompoundBoxInput
          setValue={setValue}
          length={item.length}
          totalLength={totalLength}
          value={value}
          index={item.index}
          refSet={refSet}
          key={item.index}
          startCharIdx={item.startCharIdx}
        />
      ))}
    </>
  );
}
