import React from "react";
import { Provider } from "react-redux";
import ErrorDisplay from "../features/error-display/ErrorDisplay";
import Footer from "../features/footer/Footer";
import Internationalisation from "../features/internationalisation/Internationalisation";
import Router from "./Router";
import { store } from "./store";

export function RootApp() {
  return (
    <Provider store={store}>
      <Internationalisation>
        <>
          <ErrorDisplay>
            <Router />
          </ErrorDisplay>
          <Footer />
        </>
      </Internationalisation>
    </Provider>
  );
}
