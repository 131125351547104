import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ErrorsState {
  message: string | null;
}

const initialState: ErrorsState = { message: null };

export const errorSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    showError(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },
    clearError(state) {
      state.message = null;
    },
  },
});

export const { showError, clearError } = errorSlice.actions;
