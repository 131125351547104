import { useContext, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { LoadingReactContext } from "../../features/loading-context/LoadingContext";
import { PublicRegistration } from "../../types/registration";

export default function useShouldShowErrors(
  fieldName: keyof PublicRegistration
) {
  const isLoading = useContext(LoadingReactContext);
  const {
    formState: { dirtyFields, submitCount },
  } = useFormContext();

  const fieldIsDirty = dirtyFields[fieldName] === true;

  return useMemo(() => {
    if (submitCount === 0) {
      return false;
    } else if (isLoading) {
      return false;
    } else if (fieldIsDirty) {
      return false;
    }
    return true;
  }, [isLoading, fieldIsDirty, submitCount]);
}
