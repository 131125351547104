import {
  PublicRegistration,
  RegistrationId,
} from "../../../types/registration";
import { api } from "../index";
import { TagTypes } from "../tags";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRegistration: builder.query<PublicRegistration, RegistrationId>({
      query: (id) => `/registrations/${id}/`,
      providesTags: (result) =>
        result
          ? [
              { type: TagTypes.Registration, id: result.id },
              { type: TagTypes.RegistrationTransition, id: result.id },
            ]
          : [],
    }),
  }),
});

export const { useGetRegistrationQuery } = extendedApi;
