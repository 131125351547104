import { NanoIcon } from "@nanoporetech-digital/components-react";
import Layout, { LayoutContent } from "../layout/Layout";

interface ResumeRegistrationProps {
  useExisting: () => void;
  startAgain: () => void;
}

export default function ResumeRegistration({
  useExisting,
  startAgain,
}: ResumeRegistrationProps) {
  return (
    <Layout>
      <LayoutContent>
        <h1>Welcome back</h1>
        <p>
          You've already started registering your details. Would you like to
          continue or start again?
        </p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button className="button button--secondary" onClick={startAgain}>
            Start again <NanoIcon name="light/redo" />
          </button>
          <button className="button button--primary" onClick={useExisting}>
            Continue <NanoIcon name="light/chevron-right" />
          </button>
        </div>
      </LayoutContent>
    </Layout>
  );
}
