import { Paginated } from "../../../types/api";
import { SelectableOption } from "../../../types/options";
import { api } from "../index";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrganisationTypeOptions: builder.query<SelectableOption[], void>({
      query: () => `/organisation_type_options/`,
      transformResponse: (response: Paginated<SelectableOption>) =>
        response.results,
    }),
  }),
});

export const { useGetOrganisationTypeOptionsQuery } = extendedApi;
