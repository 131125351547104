import { Paginated } from "../../../types/api";
import { Region } from "../../../types/options";
import { api } from "../index";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRegionsForCountry: builder.query<Region[], string>({
      query: (countryId: string) => `/regions/?country=${countryId}`,
      transformResponse: (response: Paginated<Region>) => response.results,
    }),
  }),
});

export const { useGetRegionsForCountryQuery } = extendedApi;
