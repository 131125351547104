import styles from "./Crumbs.module.scss";

export interface Crumb {
  active: boolean;
  label: string;
  key: string;
}

interface CrumbsProps {
  values: Crumb[];
}

export default function Crumbs({ values }: CrumbsProps) {
  return (
    <article className={styles.crumbs}>
      {values.map((value) => (
        <section className={value.active ? styles.active : ""} key={value.key}>
          {value.label}
        </section>
      ))}
    </article>
  );
}
