import { useCallback, useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { getAutoTransition } from "../../common/utils";
import RegistrationIdContext from "../../context/RegistrationIdContext";
import { useApplyTransitionMutation } from "../../services/janus/registrations/applyTransition";
import { useGetRegistrationQuery } from "../../services/janus/registrations/getRegistration";
import { useSetPasswordMutation } from "../../services/janus/registrations/setPassword";
import { useUpdateRegistrationMutation } from "../../services/janus/registrations/updateRegistration";
import { PublicRegistration } from "../../types/registration";
import ActionButtonBar from "../action-buttons/ActionButtonBar";
import InputPassword from "../input-password/InputPassword";
import InputPersonalTitle from "../input-personal-title/InputPersonalTitle";
import InputText from "../input-text/InputText";
import { LayoutContent } from "../layout/Layout";
import LoadingOverlay from "../loading-overlay/LoadingOverlay";

export default function RegformIrNoninvitedNew() {
  const id = useContext(RegistrationIdContext);
  if (id === null) {
    throw new Error("No registrations selected");
  }

  const [updateRegistration, updateRegistrationResult] =
    useUpdateRegistrationMutation();
  const [setPassword] = useSetPasswordMutation();
  const [applyTransition] = useApplyTransitionMutation();
  const {
    data: registration,
    isFetching,
    refetch,
  } = useGetRegistrationQuery(id);

  const [isSaving, setIsSaving] = useState(false);

  if (!registration) {
    throw new Error("Registration not loaded");
  }

  const form = useForm({ defaultValues: registration });

  const { handleSubmit, reset, control } = form;
  // This can autosave - see https://github.com/react-hook-form/documentation/issues/74

  // Submit the form
  const submitHandler = useCallback(
    async (values: Partial<PublicRegistration>) => {
      setIsSaving(true);

      if (values.password && values.password !== "") {
        await setPassword({ id, password: values.password }).unwrap();
      }
      const updateResult = await updateRegistration({
        id,
        data: values,
      }).unwrap();
      const transition = getAutoTransition(updateResult);

      if (transition) {
        await applyTransition({ id, transitionName: transition.name }).unwrap();
      }
      const after = await refetch().unwrap();

      reset(after);

      setIsSaving(false);
    },
    [applyTransition, refetch, reset, setPassword, id, updateRegistration]
  );

  return (
    <FormProvider {...form}>
      <LoadingOverlay
        show={isSaving || updateRegistrationResult.isLoading || isFetching}
      />
      <form onSubmit={handleSubmit(submitHandler)}>
        <LayoutContent>
          <h3>Account registration</h3>

          <p>
            To complete the registration and unlock your devices, please confirm
            the following information.
          </p>

          <aside>
            <h1 className="when-gt-mid">Privacy policy</h1>
            <p>
              Our{" "}
              <a
                href="https://nanoporetech.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                privacy policy
              </a>{" "}
              sets out how Oxford Nanopore Technologies and its subsidiaries
              handle your personal information.
            </p>
          </aside>

          <p style={{ fontSize: ".8rem" }}>
            Fields marked with * are required.
          </p>
          <h4>Personal details</h4>
          <InputPersonalTitle
            className="width-10"
            name="personal_title"
            control={control}
          />
          <InputText
            className="width-20"
            name="first_name"
            label="First name*"
            placeholder=""
            control={control}
          />
          <InputText
            className="width-20"
            name="last_name"
            label="Last name*"
            placeholder=""
            control={control}
          />
          <InputText
            className="width-30"
            name="contact_email_unverified"
            label="Email*"
            placeholder=""
            control={control}
          />
          <InputPassword
            name="password"
            label="New password*"
            placeholder=""
            className="width-25"
            control={control}
          />
          <h4>Telephone number*</h4>
          <InputText
            className="width-25"
            name="contact_telephone"
            label="Telephone*"
            placeholder=""
            control={control}
          />
        </LayoutContent>
        <ActionButtonBar
          submitStatus={updateRegistrationResult.status}
          submitResult={updateRegistrationResult.data}
          resetSubmit={updateRegistrationResult.reset}
        />
      </form>
    </FormProvider>
  );
}
