import { Control } from "react-hook-form";
import { useGetCountryOptionsQuery } from "../../services/janus/countries/getCountryOptions";
import { PublicRegistration } from "../../types/registration";
import InputSelect from "../input-select/InputSelect";

export interface InputOrganisationCountryProps {
  control: Control<PublicRegistration, any>;
  name: keyof PublicRegistration;
  label: string;
  forChannelPartner?: number | null;
}

export default function InputOrganisationCountry({
  control,
  name,
  label,
  forChannelPartner,
}: InputOrganisationCountryProps) {
  const { data: countryOptions = [] } = useGetCountryOptionsQuery({
    channelPartner: forChannelPartner || null,
  });
  const options = countryOptions.map(
    ({ synonyms: { default: label }, id }) => ({ label, value: id.toString() })
  );

  return (
    <InputSelect
      control={control}
      name={name}
      label={label}
      placeholder="-- Select --"
      className="width-20"
      options={options}
      changesInvalidate={["organisation_region"]}
    />
  );
}
