const googleRecaptchaV2SiteKey = process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY;
if (!googleRecaptchaV2SiteKey) {
  throw new Error("Must set recaptcha key");
}

const loqateApiKey = process.env.REACT_APP_LOQATE_API_KEY;
if (!loqateApiKey) {
  throw new Error("Must set loqate key");
}

const build = {
  commit: process.env.REACT_APP_GIT_COMMIT,
  ref: process.env.REACT_APP_GIT_REF,
  root: process.env.PUBLIC_URL,
  env: process.env.NODE_ENV,
  devtools: process.env.REACT_APP_DEVTOOLS === "ON",
  internationalised: process.env.REACT_APP_INTERNATIONALISED === "ON",
  googleRecaptchaV2SiteKey: googleRecaptchaV2SiteKey as string,
  loqateApiKey: loqateApiKey as string,
};

export default build;
