import { useContext, useMemo } from "react";
import RegistrationIdContext from "../../context/RegistrationIdContext";
import { useGetRegistrationQuery } from "../../services/janus/registrations/getRegistration";
import { RegistrationState } from "../../types/registration";
import CatalogueComplete from "../flow-catalogue/catalogue-complete/CatalogueComplete";
import CatalogueEmailVerification from "../flow-catalogue/catalogue-email-verification/CatalogueEmailVerification";
import CatalogueEntrypoint from "../flow-catalogue/catalogue-entrypoint/CatalogueEntrypoint";
import CatalogueOrgDetails from "../flow-catalogue/catalogue-org-details/CatalogueOrgDetails";
import CataloguePersonalDetails from "../flow-catalogue/catalogue-personal-details/CataloguePersonalDetails";
import CatalogueRepeat from "../flow-catalogue/catalogue-repeat/CatalogueRepeat";
import CatalogueSelectAccount from "../flow-catalogue/catalogue-select-account/CatalogueSelectAccount";
import Layout from "../layout/Layout";
import RegformIrComplete from "../regform-ir-complete/RegformIrComplete";
import RegformIrNoninvitedDeviceDraft from "../regform-ir-noninvired-device-draft/RegformIrNoninvitedDeviceDraft";
import RegformIrNoninvitedNew from "../regform-ir-noninvited-new/RegformIrNoninvitedNew";
import RegformIrUnverified from "../regform-ir-unverified/RegformIrUnverified";
import RegformSkeleton from "../regform-skeleton/RegformSkeleton";

export interface RegistrationStage {
  component: () => JSX.Element | null;
}

export function getStage(state: RegistrationState): RegistrationStage {
  switch (state) {
    case RegistrationState.IR_NONINVITED_NEW:
      return {
        component: RegformIrNoninvitedNew,
      };
    case RegistrationState.IR_NONINVITED_DEVICE_DRAFT:
      return {
        component: RegformIrNoninvitedDeviceDraft,
      };
    case RegistrationState.IR_UNVERIFIED:
      return {
        component: RegformIrUnverified,
      };
    case RegistrationState.IR_COMPLETE:
      return {
        component: RegformIrComplete,
      };

    case RegistrationState.CATALOGUE_ENTRYPOINT:
      return {
        component: CatalogueEntrypoint,
      };
    case RegistrationState.CATALOGUE_PERSONAL_DETAILS:
      return {
        component: CataloguePersonalDetails,
      };
    case RegistrationState.CATALOGUE_ORG_DETAILS:
      return {
        component: CatalogueOrgDetails,
      };
    case RegistrationState.CATALOGUE_EMAIL_VERIFICATION:
      return {
        component: CatalogueEmailVerification,
      };
    case RegistrationState.CATALOGUE_SELECT_ACCOUNT:
      return {
        component: CatalogueSelectAccount,
      };
    case RegistrationState.CATALOGUE_COMPLETE:
      return {
        component: CatalogueComplete,
      };
    case RegistrationState.CATALOGUE_REPEAT:
      return {
        component: CatalogueRepeat,
      };

    default:
      throw new Error(`Registration state ${state} is not implemented`);
  }
}

export default function RegistrationFlow() {
  const id = useContext(RegistrationIdContext);
  if (id === null) {
    throw new Error("No registrations selected");
  }

  const { data: registration } = useGetRegistrationQuery(id);

  const crumbs = useMemo(
    () => [
      {
        label: "Your Device",
        key: "0",
        active:
          (registration &&
            registration.state === RegistrationState.CATALOGUE_ENTRYPOINT) ||
          false,
      },
      {
        label: "Personal details",
        key: "1",
        active:
          (registration &&
            registration.state ===
              RegistrationState.CATALOGUE_PERSONAL_DETAILS) ||
          false,
      },
      {
        label: "Organisation details",
        key: "2",
        active:
          (registration &&
            registration.state === RegistrationState.CATALOGUE_ORG_DETAILS) ||
          false,
      },
      {
        label: "Verify email",
        key: "3",
        active:
          (registration &&
            registration.state ===
              RegistrationState.CATALOGUE_EMAIL_VERIFICATION) ||
          false,
      },
    ],
    [registration]
  );

  let inner = <RegformSkeleton />;

  if (registration) {
    const stage = getStage(registration.state);
    inner = <stage.component />;
  }

  return <Layout crumbs={crumbs}>{inner}</Layout>;
}
