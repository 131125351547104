import { useContext } from "react";
import RegistrationIdContext from "../../../context/RegistrationIdContext";
import { useGetRegistrationQuery } from "../../../services/janus/registrations/getRegistration";
import { LayoutContent } from "../../layout/Layout";
import RegistrationConclusion from "./RegistrationConclusion";

export default function CatalogueComplete() {
  const id = useContext(RegistrationIdContext);
  if (id === null) {
    throw new Error("Registration not selected.");
  }
  const { data: registration } = useGetRegistrationQuery(id);

  if (!registration) {
    throw new Error("Registration not loaded.");
  }

  return (
    <LayoutContent>
      <RegistrationConclusion state={registration.progress_state} />
    </LayoutContent>
  );
}
