import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import build from "../../common/build";
import { apiUrls } from "../config";
import {
  LoqateRetrieveRawResult,
  LoqateRetrieveResult,
  LoqateSearchPayload,
  LoqateSearchRawResult,
  LoqateSearchResult,
} from "./types";

export const api = createApi({
  reducerPath: "loqateApi",
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrls.loqate,
    credentials: "omit",
    timeout: 5000,
  }),
  endpoints: (builder) => ({
    searchForAddresses: builder.query<
      LoqateSearchResult[],
      LoqateSearchPayload
    >({
      query({ searchTerm, country }) {
        if (country) {
          return `/Find/v1.1/json3.ws?Bias=true&Key=${
            build.loqateApiKey
          }&Text=${encodeURIComponent(
            searchTerm || " "
          )}&Countries=${encodeURIComponent(country)}`;
        } else {
          return `/Find/v1.1/json3.ws?Bias=true&Key=${
            build.loqateApiKey
          }&Text=${encodeURIComponent(searchTerm || "")}`;
        }
      },
      transformResponse: (response: LoqateSearchRawResult) =>
        response.Items.filter((item) => !("Error" in item)),
    }),
    listAddresses: builder.query<LoqateSearchResult[], string>({
      query: (container) =>
        `Find/v1.1/json3.ws?Key=${
          build.loqateApiKey
        }&Container=${encodeURIComponent(container)}`,
      transformResponse: (response: LoqateSearchRawResult) =>
        response.Items.filter((item) => !("Error" in item)),
    }),
    getFullAddress: builder.query<LoqateRetrieveResult | null, string>({
      query: (id) =>
        `/Retrieve/v1.20/json3.ws?Key=${
          build.loqateApiKey
        }&Id=${encodeURIComponent(id)}`,
      transformResponse: (response: LoqateRetrieveRawResult) =>
        response.Items[0] || null,
    }),
  }),
});

export const {
  useSearchForAddressesQuery,
  useGetFullAddressQuery,
  useListAddressesQuery,
} = api;
