import { NanoSpinner } from "@nanoporetech-digital/components-react";
import styles from "./LoadingOverlay.module.scss";

interface LoadingOverlayProps {
  show: boolean;
}

export default function LoadingOverlay({ show }: LoadingOverlayProps) {
  return (
    <div className={`${styles.container} ${show ? styles.show : ""}`}>
      <NanoSpinner type="circle" />
    </div>
  );
}
