import { NanoSkeleton } from "@nanoporetech-digital/components-react";
import { useContext } from "react";
import RegistrationIdContext from "../../context/RegistrationIdContext";
import { useGetRegistrationQuery } from "../../services/janus/registrations/getRegistration";
import { transitionIsForward } from "../../types/registration";
import UnresolvedAction from "./UnresolvedAction";

export default function BestFitOption() {
  const id = useContext(RegistrationIdContext);
  if (!id) {
    throw new Error("No registration selected");
  }
  const { data: registration } = useGetRegistrationQuery(id);
  if (!registration) {
    return <NanoSkeleton />;
  }

  const bestFit = registration.actions.all
    .filter(transitionIsForward)
    .sort((a, b) => (a.priority > b.priority ? 1 : -1))
    .find(() => true);

  if (bestFit === undefined) {
    return null;
  }
  return (
    <UnresolvedAction
      buttonType="submit"
      transition={bestFit}
      loading={false}
    />
  );
}
