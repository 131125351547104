import { useAsyncError, useRouteError } from "react-router-dom";
import Layout, { LayoutContent } from "../features/layout/Layout";

export default function UnknownError() {
  const routerError = useRouteError();
  const asyncError = useAsyncError();

  console.error(routerError || asyncError);

  return (
    <Layout>
      <LayoutContent>
        <h1>Something went wrong.</h1>
        <p>
          Your registration ran into a problem. It may help to reload the page.
        </p>
        <p>
          If the problem persists, please contact{" "}
          <a href="https://nanoporetech.com/support/">customer support</a> to
          complete registration.
        </p>
      </LayoutContent>
    </Layout>
  );
}
